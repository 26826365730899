<template>
  <section>
    <div class="container">
      <div class="left">
        <!-- <img
          src="https://cdn.pixabay.com/photo/2017/05/09/03/46/alberta-2297204_1280.jpg"
          alt=""
          height="300"
          width="450"
        /> -->
        <v-carousel
          cycle
          height="330"
          hide-delimiter-background
          show-arrows-on-hover
        >
          <v-carousel-item
            v-for="(item, i) in items"
            :key="i"
            :src="item.src"
            reverse-transition="fade-transition"
            transition="fade-transition"
            width="500"
          ></v-carousel-item>
        </v-carousel>
      </div>
      <div class="right">
        <v-card height="300" width="500" class="cont2 pt-2">
          <div class="content">
            <h2 class="heading">MI NOTE 9 PRO MAX</h2>
            <p class="h2 font-weight-black mt-2">
              <span class="text--secondary">Brand :</span> xiomi
            </p>
            <p class="h2 font-weight-black d-flex">
              <span class="text--secondary">Rating :</span>
              <!-- <span style="font-size: 130%; color: yellow"
                >&starf; &starf; &starf; &starf;</span
              >(3) -->
              <v-rating
                dense
                disabled
                readonly
                size="16"
                v-model="rating"
                color="yellow darken-3"
                background-color="grey darken-1"
              ></v-rating>
            </p>
            <h2 class="pink--text">$12000</h2>
            <p class="h2 text--secondary">Stock Available</p>

            <v-btn @click="addToCart" class="pink white--text">
              <v-icon left>mdi-cart</v-icon>
              Add To Cart
            </v-btn>

            <p class="h2 mt-5 font-weight-black">
              <span class="text--secondary">Sold By:</span> Webilicious
            </p>
          </div>
        </v-card>
      </div>
    </div>

    <div class="tab">
      <v-card height="320" width="500" class="cont2 pt-2 white">
        <v-tabs class="ml-4">
          <v-tab class="red--text">Decsription</v-tab>
          <v-tabs-slider color="red"></v-tabs-slider>
        </v-tabs>
        <!-- <v-card-text> -->
        <div class="details">
          <h2 class="heading pb-3 mt-2">Specifications</h2>
          <p class="h2">Brand : Xiomai</p>
          <p class="h2">Model : SS5555</p>
          <p class="h2">New technolgy Branded Mobile</p>
          <p class="h2">Feature : 5G , High speed connection</p>
          <p class="h2">Made by Webilicious</p>
        </div>
        <!-- </v-card-text> -->
      </v-card>
    </div>
  </section>
</template>

<script>
import { cartService } from "../../services/index";

export default {
  name: "product",

  data() {
    return {
      rating: 2.5,
      productId: this.$route.params.id,
      items: [
        {
          src: "https://images.pexels.com/photos/5750001/pexels-photo-5750001.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
          src: "https://images.unsplash.com/photo-1512499617640-c74ae3a79d37?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1373&q=80",
        },
        {
          src: "https://images.pexels.com/photos/5749999/pexels-photo-5749999.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
          src: "https://images.unsplash.com/photo-1591337676887-a217a6970a8a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80",
        },
      ],
    };
  },
  methods: {
    addToCart() {
      console.log("ProductId", this.productId);
      cartService.add(this.productId);
    },
  },
};
</script>

<style lang="scss" scoped>
// section {
//   color: #f6f9fc;
// }
.container {
  display: flex;
  justify-content: space-evenly;

  .right {
    margin-top: 15px;
  }
}

.tab {
  align-items: center;
  margin-left: 120px;
  // display: grid;
  // place-items: left;
}
.details,
.content {
  margin-left: 25px;
}
.v-card {
  background-color: #fff;
  padding: 0px 40px 20px 40px;
  border-radius: 10px;
  box-shadow: 0px 6px 12px rgba(24, 39, 75, 0.12),
    0px 8px 24px rgba(24, 39, 75, 0.08);
  margin-bottom: 20px;
}
//
@media (max-width: 780px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .right {
      margin-top: 15px;
    }
  }

  // .v-tabs {
  //   padding-left: 250px;
  // }
  // .cont2 {
  //   margin-left: 250px;
  // }
}
</style>